import React, {useState} from 'react';
import Expander from '@nib-components/expander';
import {FirstName} from '@nib/form-fields';
import {Box} from '@nib/layout';
import {withVariants} from '../../../WithVariants';
import {FullStoryMask} from '@nib-components/form-control';
import StandardBlue from '../../../InfoBoxes/StandardBlue';

const FirstNameWithMessage = ({onBlur, ...otherProps}) => {
  const [firstName, setFirstName] = useState(false);
  const handleBlur = (event) => {
    setFirstName(event.target.value);
    onBlur(event);
  };

  // width extracted from mesh:
  const maxWidth = '23.02em';

  return (
    <div>
      <FirstName {...otherProps} onBlur={handleBlur} />
      <Box maxWidth={maxWidth}>
        <Expander expanded={firstName}>
          <StandardBlue withTip={true} tipLocation="left" withIcon={false}>
            <b>
              Hi <FullStoryMask inline>{firstName}</FullStoryMask>, nice to meet you!
            </b>
          </StandardBlue>
        </Expander>
      </Box>
    </div>
  );
};

const variants = {
  nib: FirstNameWithMessage
};

export const FirstNameWithVariants = withVariants(variants, FirstName);
export default FirstNameWithVariants;
