import {State} from '@nib/phi-constants';

import {parse} from 'qs';
import React, {useEffect, useMemo} from 'react';
import {graphql} from 'gatsby';
import {useDispatch, useSelector} from 'react-redux';
import QuotePageLayout from '../components/PageLayout/QuotePageLayout';
import {WelcomePageOwnProps} from '../types/pages';
import {ARHIPageIndex, ARHIPageList} from '../constants';
import WelcomeForm from '../components/Forms/Welcome';
import {saveFunnelProgress, setLastCompletedFunnelPage, validSourceCode} from '../redux/session/thunks';
import {getFetchedSession, getSessionError} from '../redux/session/selectors';
import {trackJoinHealthFunnelStart} from '../redux/metrics/thunks';
import {useTealiumBusinessSegmentStatic} from '../hooks/UseTealiumBusinessSegment';
import {getCookieSessionId, hasCookie} from '../services/cookies';
import {parseExcess, parseResidentType, parseScale} from './utils';
import {QueryParams} from '../components/Forms/Welcome/types';
import {useTealiumPageTrack} from '../hooks/UseTealiumPageTrack';
import * as queryString from 'query-string';

const Welcome: React.FC<WelcomePageOwnProps> = ({location, data}) => {
  const dispatch = useDispatch();
  const segment = useTealiumBusinessSegmentStatic();
  const fetchedSession = useSelector(getFetchedSession);
  const sessionId = getCookieSessionId();
  const sessionError = useSelector(getSessionError);
  const hasSessionIdButNotFetched = useMemo(() => Boolean(sessionId && !fetchedSession), [sessionId, fetchedSession]);
  // We need this so that we can hydrate the form with session values.
  // But if there is an issue loading their session, still show the form and let them see failures at the hospital page
  const showPageContent = useMemo(() => Boolean(!sessionId || (sessionId && (fetchedSession || sessionError))), [sessionId, fetchedSession, sessionError]);

  // if existing user, wait for session. But if the session load fails, render form anyway
  useTealiumPageTrack(ARHIPageList[ARHIPageIndex.WELCOME], true);

  useEffect(() => {
    const resetProgress = queryString.parse(location.search, {parseBooleans: true})?.rp;

    if (sessionId && !hasSessionIdButNotFetched && resetProgress) {
      dispatch(setLastCompletedFunnelPage(ARHIPageList[ARHIPageIndex.WELCOME]));
    } else if (!hasSessionIdButNotFetched) {
      dispatch(saveFunnelProgress(ARHIPageList[ARHIPageIndex.WELCOME]));
    }

    if (!hasCookie()) {
      dispatch(trackJoinHealthFunnelStart(segment, 'Welcome'));
    }
  }, [dispatch, segment, hasSessionIdButNotFetched, sessionId, location.search]);

  const {state, scale, excess, residentType, sourceCode} = parse(location.search.slice(1));

  const queryParams: QueryParams = {
    state: State[`${state}`] ?? undefined,
    scale: scale ? parseScale(scale.toString()) : undefined,
    excess: excess ? parseExcess(excess.toString()) : undefined,
    residentType: residentType && parseResidentType(residentType.toString()),
    sourceCode: validSourceCode(sourceCode as string) ? (sourceCode as string) : undefined
  };

  const formProps = {tealiumBusinessSegment: segment, queryParams};

  return (
    <QuotePageLayout pageData={data.pageDetails.metadata} hidePageContents={!showPageContent} showCorporateBanner>
      <WelcomeForm {...formProps} />
    </QuotePageLayout>
  );
};

export default Welcome;

/**
 * Gatsby will present the following error when build is run under "nibP2PWeb" as this brand does not have a welcome page and thus this
 * component is not considered a page component.
 *
 * warn The GraphQL query in the non-page component "/Users/m.leontieff/Development/arhi-join-web/src/templates/welcome.tsx" will not be run.
 */
export const query = graphql`
  query WelcomePageQuery {
    pageDetails(template: {eq: "welcome"}) {
      metadata {
        title
        robots
      }
    }
  }
`;
