import React from 'react';
import InfoBox, {InfoBoxProps} from '@nib/info-box';
import styled from 'styled-components';
import {breakpoint} from '@nib/layout';
import {token} from '@nib-components/theme';

export interface InformationalProps extends InfoBoxProps {
  /* In a particular form construction, an adjacent FormControl element may exhibit margin spacing from
  an instance of a InfoBox component such that the two elements are too far apart to appear related to each other.
  This prop allows for a corrective negative margin to be enabled (bad practice I know) to compensate for this in particular situations. */
  formControlOffset?: boolean;
}

const Informational = (props: InformationalProps) => {
  return (
    <InformationalPosition formControlOffset={props.formControlOffset}>
      <InfoBox role="status" withTip iconAndBorderColor={token('theme.color.fg.info')} {...props}>
        {props.children}
      </InfoBox>
    </InformationalPosition>
  );
};

const InformationalPosition = styled.div<{formControlOffset?: boolean}>`
  margin-top: ${(props) => props.formControlOffset && '-1rem'};
  ${breakpoint('sm')`
    margin-top: ${(props) => props.formControlOffset && '-2.5rem'};
  `}
`;

export default Informational;
