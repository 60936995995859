import React, {MouseEvent} from 'react';
import styled from 'styled-components';
import {Formik, Field, FormikProps} from 'formik';
import {addMonths} from 'date-fns';
import {Box} from '@nib/layout';
import validate from './validation';
import {Phone, Email, DateOfBirth, FunnelProvider, FUNNELS, Scale, CoverStartDate, YesNoRadio as VisitorTypeRadio, generateNameMask} from '@nib/form-fields';
import {PrimaryButton, SecondaryButton} from '@nib-components/button';
import {Scale as SCALE} from '@nib/phi-constants';
import {RewardsGraphicIcon, CalendarGraphicIcon, ChevronRightSystemIcon, GiftGraphicIcon, ContentGraphicIcon, IconType} from '@nib/icons';
import {FIELD_LABELS, FIELD_NAMES, getResidentTypeGroupProps, VISITOR_TYPES} from './constants';
import {WelcomeDetailsForm, WelcomeDetailsValues} from './types';
import {FUNNEL_NAME, getBrand, isNibBrand, createServerDate} from '../../../services/utils';
import Heading from '@nib-components/heading';
import OnSubmitValidationErrorScrollTo from '../OnSubmitValidationErrorScrollTo';
import StyledForm from '../StyledForm';
import {saveFormOnBlur, saveFormOnDOBChange, saveFormOnChange, saveFormOnDateChange} from './eventHandlers';
import {connectValidationContext} from '../utils';
import {useValidationContext} from '../../ValidationContext';
import {useWelcomeStatic} from '../../../hooks/UseWelcomeStatic';
import {CopyWithColoredLink} from '../../Links/CopyWithColoredLink';
import PrivacyMessage from '../../PrivacyMessage';
import {useHasMounted} from '../../../hooks/UseHasMounted';
import Expander from '@nib-components/expander';
import {withVariants} from '../../WithVariants';
import {ResidentTypeGroup} from './ResidentTypeGroup';
import {Funds} from '@nib/phi-constants';
import CustomFirstName from './CustomFirstName';
import {nib} from '@nib-components/theme';
import Copy from '@nib-components/copy';
import Alert from '@nib-components/alert';
import {createMarkup, getCurrentDate} from '../../../utils';
import StandardBlue from '../../InfoBoxes/StandardBlue';

const InfoBoxIcon = () => {
  const variants = {
    nib: RewardsGraphicIcon,
    apia: CalendarGraphicIcon,
    priceline: GiftGraphicIcon
  };

  return withVariants(variants, CalendarGraphicIcon)({variant: getBrand(), size: 'lg'} as any);
};

const ContactHiddenCheckbox = styled(Field).attrs({type: 'checkbox'})`
  display: none;
  visibility: hidden;
`;

export const EXISTING_CUSTOMER_TYPE: keyof typeof VISITOR_TYPES = 'Customer';
export const CHANGE_COVER_LINK = 'https://go.nib.com.au/Y0LF/changecover';

const Component: React.FC<WelcomeDetailsForm> = (props) => {
  const {handleSubmit, updateSession, initialValues, showArhiPPCInformation, referAFriendMembershipNumber} = props;
  const validationContext = useValidationContext();
  const {fieldLabels, formTitle, rewardsInfoBox} = useWelcomeStatic();
  const todayAest = createServerDate(getCurrentDate());
  const monthFromToday = addMonths(todayAest, 1);

  const handleMouseDown = (event: MouseEvent) => {
    if (event && event.target && event.type === 'mousedown') {
      const target = event.target;
      if (target instanceof HTMLElement && typeof target.click == 'function') {
        target.click();
      }
    }
  };

  // Two-pass rendering to allow using dynamic scale value from URL query.
  // Forcing render after mount.
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  let headingColor: string,
    headingSize: any = {xs: 1};
  if (isNibBrand()) {
    headingColor = nib.colors.brand.dark;
    headingSize = {xs: 2, xl: 1};
  }

  return (
    <Box margin="auto" maxWidth="768px" padding={6}>
      <FunnelProvider value={{funnel: FUNNELS[FUNNEL_NAME]}}>
        <Formik validate={validate(FUNNEL_NAME)} onSubmit={handleSubmit} initialValues={initialValues}>
          {(formikBag: FormikProps<WelcomeDetailsValues>) => {
            connectValidationContext(validationContext, formikBag);

            return (
              <>
                <Box marginTop={4} marginBottom={6}>
                  <Heading component="h1" size={headingSize} color={headingColor}>
                    {formTitle}
                  </Heading>
                </Box>

                {referAFriendMembershipNumber && (
                  <Box marginTop={8} marginBottom={8}>
                    <StandardBlue title="Friends helping friends." icon={ContentGraphicIcon}>
                      <Copy>Your friend wants you to find a great health cover that&apos;s right for you.</Copy>
                    </StandardBlue>
                  </Box>
                )}

                <Box marginBottom={6}>
                  <PrivacyMessage />
                </Box>

                <StyledForm name="welcome-form-info" id="welcome-form-info" onSubmit={formikBag.handleSubmit} onMouseDown={handleMouseDown} formType="optional">
                  <OnSubmitValidationErrorScrollTo />

                  <ContactHiddenCheckbox id="contact" name="contact" />

                  <Field
                    variant={getBrand()}
                    name={FIELD_NAMES.FIRST_NAME}
                    id={FIELD_NAMES.FIRST_NAME}
                    label={fieldLabels[FIELD_NAMES.FIRST_NAME]}
                    component={CustomFirstName}
                    maxLength={24}
                    mask={generateNameMask(24)}
                    onBlur={saveFormOnBlur(formikBag, updateSession)}
                  />

                  <ResidentTypeGroup variant={getBrand() as any} {...getResidentTypeGroupProps(formikBag, fieldLabels)} />
                  {formikBag.values.residentType === (Funds.ARHI || undefined) && (
                    <>
                      <Field name={FIELD_NAMES.SCALE} id={FIELD_NAMES.SCALE} label={fieldLabels[FIELD_NAMES.SCALE]} help="" component={Scale} onChange={saveFormOnChange(formikBag, updateSession)} />
                      <Field
                        name={FIELD_NAMES.DATE_OF_BIRTH}
                        id={FIELD_NAMES.DATE_OF_BIRTH}
                        label={fieldLabels[FIELD_NAMES.DATE_OF_BIRTH]}
                        component={DateOfBirth}
                        onDateBlur={saveFormOnDOBChange(formikBag, updateSession)}
                      />
                      {[SCALE.Family, SCALE.Couple].includes(formikBag.values?.scale) && (
                        <Field
                          name={FIELD_NAMES.PARTNER_DATE_OF_BIRTH}
                          id={FIELD_NAMES.PARTNER_DATE_OF_BIRTH}
                          label={fieldLabels[FIELD_NAMES.PARTNER_DATE_OF_BIRTH]}
                          component={DateOfBirth}
                          onDateBlur={saveFormOnDOBChange(formikBag, updateSession, FIELD_NAMES.PARTNER_DATE_OF_BIRTH)}
                        />
                      )}

                      {/* Optional field toggle-able by Optimizely - off by default */}
                      <Box display="none" data-exp-id="welcome-experiment-oba-email">
                        <Field name={FIELD_NAMES.EMAIL} id={FIELD_NAMES.EMAIL} label={fieldLabels[FIELD_NAMES.EMAIL]} component={Email} onBlur={saveFormOnBlur(formikBag, updateSession)} />
                      </Box>

                      {/* Optional field toggle-able by Optimizely - on by default */}
                      <Box display="inherit" data-exp-id="welcome-experiment-oba-phone">
                        <Field
                          name={FIELD_NAMES.PHONE_NUMBER}
                          id={FIELD_NAMES.PHONE_NUMBER}
                          label={fieldLabels[FIELD_NAMES.PHONE_NUMBER]}
                          component={Phone}
                          maxLength={10}
                          onBlur={saveFormOnBlur(formikBag, updateSession)}
                        />
                      </Box>

                      {showArhiPPCInformation && (
                        <Expander expanded={showArhiPPCInformation}>
                          <Field
                            name={FIELD_NAMES.COVER_START_DATE}
                            id={FIELD_NAMES.COVER_START_DATE}
                            component={CoverStartDate}
                            months={1}
                            minDate={todayAest}
                            maxDate={monthFromToday}
                            label={FIELD_LABELS.COVER_START_DATE}
                            onChange={saveFormOnDateChange(formikBag, updateSession, FIELD_NAMES.COVER_START_DATE)}
                          />
                        </Expander>
                      )}
                      {isNibBrand() && (
                        <>
                          <Field component={VisitorTypeRadio} name={FIELD_NAMES.VISITOR_TYPE} id={FIELD_NAMES.VISITOR_TYPE} options={VISITOR_TYPES} label={FIELD_LABELS.VISITOR_TYPE} inline={false} />
                          {formikBag.values?.visitorType === EXISTING_CUSTOMER_TYPE && (
                            <Alert type="warning" variation="soft">
                              If you are an nib member, you can login to your member account to compare or change your cover, update your excess, add Extras cover, add or remove people to your policy
                              and more.
                            </Alert>
                          )}
                        </>
                      )}

                      <Box marginLeft={{xs: 0, lg: 0}}>
                        {/* Existing customers are prompted to the members login page or to "start new quote" */}
                        {formikBag.values?.visitorType === EXISTING_CUSTOMER_TYPE ? (
                          <Box display={{xs: 'flex'}} flexWrap="wrap-reverse">
                            <Box flexGrow={{xs: 1, md: 0}} marginTop={{xs: 4, md: 0}} marginRight={{xs: 0, md: 4}}>
                              <PrimaryButton href={CHANGE_COVER_LINK} fullWidth={{xs: true, md: false}} icon={ChevronRightSystemIcon}>
                                Login
                              </PrimaryButton>
                            </Box>
                            <SecondaryButton fullWidth={{xs: true, md: false}} type="submit" isLoading={formikBag.isSubmitting} disabled={formikBag.isSubmitting}>
                              Start a new quote
                            </SecondaryButton>
                          </Box>
                        ) : (
                          <PrimaryButton fullWidth={{xs: true, md: false}} icon={ChevronRightSystemIcon} type="submit" isLoading={formikBag.isSubmitting} disabled={formikBag.isSubmitting}>
                            Start
                          </PrimaryButton>
                        )}
                      </Box>
                    </>
                  )}
                  <StandardBlue titleComponent="h4" title={rewardsInfoBox.headingText} icon={InfoBoxIcon as unknown as IconType}>
                    <CopyWithColoredLink small={true} dangerouslySetInnerHTML={createMarkup(rewardsInfoBox.bodyText)} />
                  </StandardBlue>
                </StyledForm>
              </>
            );
          }}
        </Formik>
      </FunnelProvider>
    </Box>
  );
};

export default Component;
