import React from 'react';
import Layout, {PureLayoutProps} from '../Layout';
import Loading from '../Loading';
import {useSelector} from 'react-redux';
import {getSessionError} from '../../redux/session/selectors';
import {getPricingError} from '../../redux/pricing/selectors';
import {noPriceMessage} from '../../constants';
import ErrorAlert from '../Alerts/ErrorAlert';
import {Container} from '@nib/layout';

type QuotePageLayoutProps = {
  hidePageContents?: boolean;
  pageData?: PureLayoutProps['pageData'];
  showCorporateBanner?: boolean;
};

const QuotePageLayout: React.FC<QuotePageLayoutProps> = ({children, pageData, hidePageContents, showCorporateBanner = false}) => {
  const sessionError = useSelector(getSessionError);
  const pricingError = useSelector(getPricingError);

  return (
    <Layout pageData={pageData} showCorporateBanner={showCorporateBanner}>
      <Container>
        {sessionError ? <ErrorAlert /> : pricingError && <ErrorAlert title={noPriceMessage} />}
        {hidePageContents ? <Loading center={true} /> : children}
      </Container>
    </Layout>
  );
};

export default QuotePageLayout;
